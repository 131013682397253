@import 'src/@core/styles/scss/includes.scss';
.cell {
  font-weight: var(--regular);
  font-size: 14px;
  color: var(--color-mirage);
  &__code,
  &__address {
    font-weight: var(--semi-bold);
    color: var(--color-primary);
    transition: color var(--def-transition-300ms);
    &:hover {
      color: var(--color-accent);
    }
  }
}
