@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;
  & > div {
    width: 100%;
    &:first-of-type {
      max-width: 380px;
    }
    &:last-of-type {
      max-width: calc(100% - 380px);
    }
  }
}
