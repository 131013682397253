@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  // background-color: var(--color-white);
  background-color: var(--color-whisper);
  // border-radius: 0 0 var(--border-radius) var(--border-radius);
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
}

.side {
  &__left {
    width: 32px;
  }
  &__center {
    width: calc(100% - 64px);
    text-align: center;
  }
  &__right {
    width: 32px;
  }
}

.title {
  display: block;
  max-width: 90%;
  width: 100%;
  font-weight: var(--medium);
  font-size: 16px;
  color: var(--color-mirage);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}

.action {
  display: inline-block;
  width: 32px;
  height: 24px;
  cursor: pointer;
}
