@mixin abs-center() {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@mixin hover {
  &:hover {
    @include rsp-from(ltp) {
      @content;
    }
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin fieldAutofill {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @content;
  }
}

@mixin fieldAutofillText {
  &:-webkit-autofill::first-line {
    @content;
  }
}
