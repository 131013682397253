[class*='fx'] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.fx-nowrap {
    flex-wrap: nowrap;
  }
  &.fx-row-reverse {
    flex-flow: row-reverse;
  }
  &.fx-row {
    flex-flow: row;
  }
  &.fx-column {
    flex-flow: column;
  }
  &.fx-align {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }
  &.fx-content {
    &-start {
      justify-content: center;
    }
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
    }
    &-end {
      justify-content: flex-end;
    }
  }
}
