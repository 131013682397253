@import 'src/@core/styles/scss/includes.scss';
.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 -7.5px;
  & > div {
    width: 100%;
    padding: 0 7.5px;
  }
}
