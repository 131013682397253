main {
  height: 100vh;
}

.layout {
  &__auth {
    background-color: var(--color-whisper);
    &__form_wrapper {
      padding: 20px 15px;
    }
  }
  &__dashboard,
  &__info {
    height: 100vh;
    background-color: var(--color-whisper);
    &__mobile,
    &__android,
    &__ios {
      height: calc(var(--bars-height-unit, 1vh) * 100);
      padding-top: var(--tool-bar-height);
      padding-bottom: var(--bottom-nav-bar-height);
      position: fixed;
      top: 0;
      left: 0;
    }
    &__desktop {
      .page {
        padding-top: var(--top-nav-bar-height);
        padding-left: 260px;
        padding-bottom: var(--bottom-help-bar-height);
      }
    }
    &__info {
      .page {
        padding-top: calc(var(--top-nav-bar-height) + 10px) !important;
        padding-left: 275px; // 240
        padding-bottom: 0;
      }
    }
  }
}

.page {
  height: 100%;
  &__dashboard,
  &__info {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    &__external {
      padding: 60px 0 0;
    }
    &__inner {
      width: 100%;
      height: 100%;
      padding: 15px;
      position: relative;
      overflow-y: auto;
    }
  }
  &__info {
    &__desktop {
      padding-top: calc(var(--top-nav-bar-height) + 10px) !important;
      padding-left: 275px !important;

      .page__info__inner {
        justify-content: flex-start;
      }
    }
  }
}
