.x-fullscreen {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.x-img {
  width: 100%;
  height: 100%;
  &.abs {
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &.center {
    -o-object-position: center;
    object-position: center;
  }
  &.cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
  &.contain {
    -o-object-fit: contain;
    object-fit: contain;
  }
  &-hidden {
    visibility: hidden;
  }
}

.x-align {
  &-l {
    text-align: left;
  }
  &-c {
    text-align: center;
  }
  &-r {
    text-align: right;
  }
}

.text {
  &-capitalize {
    text-transform: capitalize;
  }
}
