.popup {
  width: 100%;
  height: 100%;
  background-color: set-color(--color-black, 0);
  padding: 30px 15px;
  pointer-events: none;
  transition: background-color 300ms 300ms var(--a);
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  &__active {
    pointer-events: all;
    background-color: set-color(--color-black, 0.3);
    transition: background-color 300ms var(--a);
    .popup {
      &__inner,
      &__content {
        opacity: 1;
        transition: opacity 300ms var(--a), transform 300ms var(--a);
        transform: translateY(0) scale(1);
      }
    }
  }
  &__inner,
  &__content {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    opacity: 0;
    transform: translateY(15px) scale(0.9);
    transition: opacity 300ms 300ms var(--a), transform 300ms 300ms var(--a);
    &__close {
      width: 20px;
      height: 20px;
      transform: translate(-10px, 10px);
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
