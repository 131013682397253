@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  text-align: center;
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  padding: 20px 15px;
  h4 {
    font-size: 20px;
    color: var(--color-white);
    margin: 0 0 15px;
  }
}
