@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  padding: 0 8px;
  &__width {
    &_25 {
      width: 25%;
    }
    &_50 {
      width: 50%;
    }
    &_75 {
      width: 75%;
    }
    &_100 {
      width: 100%;
    }
  }
  &__inner {
    position: relative;
  }
}

.field_wrapper {
  width: 100%;
  position: relative;
  &__checkbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.field {
  &__text,
  &__email,
  &__password,
  &__number,
  &__textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    color: var(--color-mirage);
    background-color: var(--color-white);
    border: 2px solid var(--color-mystic);
    border-radius: var(--border-radius);
    padding: 16px;
    transition: color var(--def-transition-300ms),
      background-color var(--def-transition-300ms),
      border-color var(--def-transition-300ms);
    @include fieldAutofill {
      -webkit-text-fill-color: var(--color-white) !important;
      -webkit-box-shadow: 0 0 0 30px var(--color-mystic) inset !important;
    }
    @include fieldAutofillText {
      font-family: var(--inter);
      font-weight: var(--regular);
      font-size: 14px;
      color: var(--color-mirage);
    }
    @mixin placeholder {
      font-size: 14px;
      color: var(--color-white);
    }
  }
  &__has_icon {
    padding: 16px 16px 16px 46px;
  }
  &__icon,
  &__loader {
    width: 24px;
    height: 24px;
    transform: translate(15px, -50%);
    position: absolute;
    top: 50%;
    left: 0;
  }
  &__loader {
    width: 24px;
    height: 24px;
    img {
      max-width: 100%;
    }
  }
  &__label {
    display: block;
    font-weight: var(--medium);
    font-size: 14px;
    margin: 0 0 5px;
  }
}

.label {
  &__checkbox {
    display: inline-block;
    font-size: 14px;
    color: var(--color-manatee);
    margin: 0 0 0 10px;
    cursor: pointer;
    user-select: none;
    position: relative;
    a {
      font-weight: var(--semi-bold);
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
