@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  &__item {
    display: block;
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &__element {
      display: block;
      font-weight: var(--medium);
      font-size: 14px;
      color: var(--color-gray);
      transition: var(--def-transition-300ms);
      padding: 0 0 5px;
      overflow: hidden;
      position: relative;
      &:hover {
        color: var(--color-primary);
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--color-primary);
        border-radius: 8px;
        transition: transform var(--def-transition-300ms);
        position: absolute;
        top: 100%;
        left: 0;
      }
      &__active {
        color: var(--color-primary);
        &:after {
          transform: translateY(-2px);
        }
      }
    }
  }
}
