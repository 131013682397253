@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  margin: 0 0 30px;
  padding: 0 30px;
  &__item {
    display: block;
    width: 100%;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 24px;
      background-color: var(--color-primary);
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      transform: translate(-34px, -50%);
      transition: transform var(--def-transition-300ms);
      position: absolute;
      top: 50%;
      left: 0;
    }
    &__active {
      &:before {
        transform: translate(-30px, -50%);
      }
    }
    &:hover {
      &:before {
        transform: translate(-30px, -50%);
      }
    }
    &__inner {
      padding: 10px 0;
    }
    &__text {
      display: inline-block;
      width: calc(100% - 34px);
      font-weight: var(--medium);
      font-size: 16px;
      color: var(--color-manatee);
      margin: 0 0 0 10px;
      transition: color var(--def-transition-300ms);
      &__active {
        color: var(--color-primary);
      }
    }
  }
}
