@import 'src/@core/styles/scss/includes.scss';
.root {
  padding: 15px 15px 25px;
}

.root,
.list {
  width: 100%;
}

.list {
  &__item {
    font-weight: var(--semi-bold);
    font-size: 12px;
    color: var(--color-manatee);
    transition: color var(--def-transition-300ms);
    &:hover {
      color: var(--color-primary);
    }
  }
}
