@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  padding: 15px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
  &__active {
    pointer-events: all;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: set-color(--color-black, 0);
    transition: background-color 300ms var(--a);
    position: absolute;
    top: 0;
    left: 0;
    &__active {
      background-color: set-color(--color-black, 0.3);
    }
  }
  &__content {
    max-width: 420px;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 20px 15px 15px;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms var(--a), transform 300ms var(--a);
    position: relative;
    z-index: 2;
    &__active {
      opacity: 1;
      transform: scale(1);
    }
    &__text {
      text-align: center;
      margin: 0 0 20px;
    }
    h3 {
      font-size: 20px;
      color: var(--color-mirage);
      margin: 0 0 5px;
    }
    p {
      font-size: 14px;
      color: var(--color-gray);
    }
  }
}
