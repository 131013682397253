@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  padding: 15px 15px 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2030;
  &__active {
    pointer-events: all;
    .root {
      &__overlay {
        opacity: 1;
      }
      &__content {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
    }
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: set-color(--color-black, 0.3);
    opacity: 0;
    transition: opacity 300ms var(--a);
    position: absolute;
    top: 0;
    left: 0;
  }
  &__content {
    width: auto;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    opacity: 0;
    transform: translateY(5px) scale(0.98);
    transition: opacity 300ms var(--a), transform 300ms var(--a);
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    &__desktop {
      flex-flow: column;
      padding: 15px 15px 20px;
      transform: scale(0.8);
    }
    &__left {
      width: 28px;
      height: 28px;
      &__desktop {
        display: flex;
        flex-flow: row;
        justify-content: center;
        width: 100%;
        height: 48px;
      }
    }
    &__right {
      width: calc(100% - 28px);
      padding: 0 0 0 10px;
      &__desktop {
        width: 100%;
        text-align: center;
        padding: 0;
        p,
        b {
          display: inline-block;
        }
        b {
          font-size: 20px;
          margin: 0 0 5px;
        }
      }
    }
    &__icon {
      display: none;
      &__visible {
        display: inline-block;
      }
      svg {
        width: 100%;
        height: auto;
      }
    }
    p {
      color: var(--color-gray);
      line-height: 1.2;
    }
    b {
      color: var(--color-mirage);
    }
  }
}
