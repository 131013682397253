@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 180px;
  padding: 0 15px 30px;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: set-color(--color-mirage, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.headline,
.description {
  position: relative;
  z-index: 2;
}

.headline {
  font-weight: var(--bold);
  font-size: 32px;
  color: var(--color-white);
  line-height: 1.1;
  margin: 0;
}

.description {
  font-weight: var(--medium);
  font-size: 14px;
  color: var(--color-white);
  margin: 15px 0 0;
  p {
    margin: 0 0 10px;
    &:last-of-type {
      margin: 0;
    }
  }
}
