@import 'src/@core/styles/scss/includes.scss';
.root {
  &__link {
    padding: 10px;
    transition: background-color var(--def-transition-300ms);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    &:hover {
      background-color: var(--color-athens-gray);
    }
    &:before {
      content: '';
      display: block;
      width: 80%;
      height: 1px;
      background-color: var(--color-athens-gray);
      transform: translateX(-50%);
      position: absolute;
      top: 0;
      left: 50%;
    }
    &__icon,
    &__text {
      display: inline-block;
    }
    &__icon {
      width: 26px;
      animation: 'tada' 1s infinite;
    }
    &__text {
      width: calc(100% - 26px);
      font-size: 14px;
      color: var(--color-primary);
      line-height: 1.2;
      padding: 0 0 0 10px;
    }
  }
}
