@use 'sass:math';

@mixin rsp-from($rz) {
  @media (min-width: #{map-deep-get($rsp, $rz, min)}px) {
    @content;
  }
}

@mixin rsp-to($rz) {
  @media (max-width: #{map-deep-get($rsp, $rz, max)}px) {
    @content;
  }
}

@mixin max-aspect-ratio($w, $h) {
  @media screen and (max-aspect-ratio: math.div(#{$w}, #{$h})) {
    @content;
  }
}

@mixin min-aspect-ratio($w, $h) {
  @media screen and (min-aspect-ratio: math.div(#{$w}, #{$h})) {
    @content;
  }
}

@mixin rsp-aspect-ratio($w, $h) {
  @media screen and (aspect-ratio: math.div(#{$w}, #{$h})) {
    @content;
  }
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@mixin rsp($min, $max: null) {
  @if ($max == null) {
    @if (map-deep-get($rsp, $min, min) == 0) {
      @media (max-width: #{map-deep-get($rsp, $min, max)}px) {
        @content;
      }
    } @else if(map-deep-get($rsp, $min, max) == 9999) {
      @media (min-width: #{map-deep-get($rsp, $min, min)}px) {
        @content;
      }
    } @else {
      @media (min-width: #{map-deep-get($rsp, $min, min)}px) and (max-width: #{map-deep-get($rsp, $min, max)}px) {
        @content;
      }
    }
  } @else {
    @media (min-width: #{map-deep-get($rsp, $min, min)}px) and (max-width: #{map-deep-get($rsp, $max, max)}px) {
      @content;
    }
  }
}

@mixin rsp-ort(
  $sizes: (
    min: null,
    max: null,
  ),
  $orientation: null
) {
  @if (
    map-has-key($sizes, min) and
      map-has-key($sizes, max) and
      $orientation !=
      null
  ) {
    @media (min-width: #{map-deep-get($rsp, map-deep-get($sizes, min), min)}px) and (max-width: #{map-deep-get($rsp, map-get($sizes, max), max)}px) and (orientation: #{$orientation}) {
      @content;
    }
  } @else if (map-has-key($sizes, min) and $orientation != null) {
    @if (map-deep-get($rsp, map-deep-get($sizes, min), min) == 0) {
      @media (max-width: #{map-deep-get($rsp, map-deep-get($sizes, min), max)}px) and (orientation: #{$orientation}) {
        @content;
      }
    } @else if(map-deep-get($rsp, map-deep-get($sizes, min), max) == 9999) {
      @media (min-width: #{map-deep-get($rsp, map-deep-get($sizes, min), min)}px) and (orientation: #{$orientation}) {
        @content;
      }
    } @else {
      @media (min-width: #{map-deep-get($rsp, map-deep-get($sizes, min), min)}px) and (max-width: #{map-deep-get($rsp, map-deep-get($sizes, min), max)}px) and (orientation: #{$orientation}) {
        @content;
      }
    }
  }
}

@mixin make-lim-columns($columns: $grid-columns, $gutter: $grid-gutter-width) {
  $list: '';
  $i: 1;
  @for $idx from 1 through $columns {
    @each $key, $val in $rsp {
      $ins: ', ';
      @if $idx == 1 and $i == 1 {
        $ins: '';
      }
      $list: '#{$list}#{$ins}.col-#{$key}-#{$idx}';
      $i: $i + 1;
    }
  }
  #{$list} {
    position: relative;
    min-height: 1px;
    padding-left: math.div($gutter, 2);
    padding-right: math.div($gutter, 2);
  }
  @for $cdx from 1 through $columns {
    @each $key, $val in $rsp {
      @include rsp-from($key) {
        .col-#{$key}-#{$cdx} {
          float: left;
          width: percentage(math.div($cdx, $columns));
        }
        .col-#{$key}-offset-#{$cdx} {
          margin-left: percentage(math.div($cdx, $columns));
        }
        .col-#{$key}-push-#{$cdx} {
          left: percentage(math.div($cdx, $columns));
        }
        .col-#{$key}-pull-#{$cdx} {
          right: percentage(math.div($cdx, $columns));
        }
      }
    }
  }
}
