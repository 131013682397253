@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 260px;
  height: 100%;
  background-color: var(--color-white);
  box-shadow: 0 4px 16px set-color(--color-mirage, 0.1);
  // overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;

  &__inner {
    width: 100%;
    height: 100%;
    min-height: 590px;
    position: relative;
  }
}

.divider {
  width: 80%;
  height: 1px;
  background-color: var(--color-athens-gray);
  margin: 30px auto;
}
