.pac-container {
  border-radius: var(--border-radius);
  top: auto !important;
  bottom: 215px !important;
  &:after {
    display: none;
  }
  & > div {
    padding: 8px 14px;
  }
}
