@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  text-align: center;
  h6 {
    font-weight: var(--regular);
    font-size: 14px;
    color: var(--color-manatee);
  }
  &__mobile {
    h6 {
      font-size: 14px;
    }
  }
  &__desktop {
    h6 {
      font-size: 16px;
    }
  }
}
