@import 'src/@core/styles/scss/includes.scss';
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1090;
  &__active {
    pointer-events: all;
  }
}

.close {
  transform: translate(-10px, 10px);
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    [stroke*='#'] {
      stroke: var(--color-primary);
    }
  }
  svg {
    * {
      transition: var(--def-transition-300ms);
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: set-color(--color-mirage, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  display: block;
  max-width: 360px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  position: relative;
}
