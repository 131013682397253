@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
}

.link {
  font-weight: var(--semi-bold);
  color: var(--color-primary);
  &:hover {
    text-decoration: underline;
  }
  &__forgot_password {
    font-size: 14px;
  }
}
