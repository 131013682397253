@import 'src/@core/styles/scss/includes.scss';
.root {
  &__status {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      overflow: hidden;
      &__lg {
        width: 48px;
        height: 48px;
      }
      &__md {
        width: 40px;
        height: 40px;
      }
      &__sm {
        width: 36px;
        height: 36px;
      }
      &__created {
        background-color: set-color(--color-medium-purple, 0.2);
      }
      &__on_route {
        background-color: set-color(--color-gold-tips, 0.2);
      }
      &__delivered {
        background-color: set-color(--color-la-palma, 0.2);
      }
      &__failed {
        background-color: set-color(--color-valencia, 0.2);
      }
    }
    &__text {
      display: inline-block;
      font-weight: var(--bold);
      font-size: 14px;
      &__created {
        color: var(--color-medium-purple);
      }
      &__on_route {
        color: var(--color-gold-tips);
      }
      &__delivered {
        color: var(--color-la-palma);
      }
      &__failed {
        color: var(--color-valencia);
      }
    }
  }
}
