@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  &__mobile {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 20px;
    margin: 0 0 20px;
  }
  &__desktop {
    margin: 60px 0 0;
  }
}

.side {
  &__top,
  &__bottom {
    width: 100%;
  }
  &__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 0 0 10px;
  }
  &__right {
    padding: 0 0 0 15px;
    &__xlg {
      width: calc(100% - 140px);
    }
    &__lg {
      width: calc(100% - 120px);
    }
    &__md {
      width: calc(100% - 80px);
    }
  }
  &__bottom {
    text-align: center;
  }
}

.avatar {
  position: relative;
  &__xlg {
    width: 140px;
    height: 140px;
  }
  &__lg {
    width: 120px;
    height: 120px;
  }
  &__md {
    width: 80px;
    height: 80px;
  }
  &__image {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    &__none {
      border: 3px solid var(--color-mystic);
    }
  }
  &__edit {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: var(--color-mystic);
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    transform: translate(8px, -8px);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  &__profile_status {
    display: block;
    width: 100%;
    font-size: 10px;
    color: var(--color-white);
    text-align: center;
    background-color: var(--color-error);
    padding: 2px 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.fullname {
  color: var(--color-mirage);
  line-height: 1;
  margin: 0;
  &__mobile {
    font-size: 20px;
  }
  &__desktop {
    font-size: 18px;
    margin: 0 0 2px;
    a {
      transition: color var(--def-transition-300ms);
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  a {
    color: var(--color-mirage);
  }
}

.meta {
  line-height: 1.2;
  &__desktop {
    margin: 0 0 5px;
  }
  &__email {
    font-size: 14px;
    color: var(--color-gray);
    &__desktop {
      transition: color var(--def-transition-300ms);
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

.link {
  font-weight: var(--semi-bold);
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
  &__verify {
    color: var(--color-sunset-orange);
    margin: 0 10px 0 0;
  }
  &__logout {
    color: var(--color-sunset-orange);
  }
  &__profile {
    color: var(--color-primary);
    margin: 0 0 0 10px;
  }
}
