@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  padding: 15px;
  .field {
    width: 100%;
    margin: 0 !important;
    & > div {
      padding: 0;
    }
    &__inner {
      border-color: var(--color-primary);
    }
    input {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  button {
    &[type='button'] {
      width: auto;
      margin: 0 0 0 15px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
