@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  &__description {
    margin: 10px 0 0;
    p {
      color: var(--color-gray);
      margin: 0;
    }
    a {
      display: inline-block;
      font-weight: var(--medium);
      color: var(--color-primary);
      text-decoration: underline;
      margin: 5px 0 0;
    }
  }
}
