.row-inline {
  font-size: 0;
  & > * {
    display: inline-block;
    vertical-align: top;
  }
}

.y-align-m {
  vertical-align: middle;
  & > * {
    vertical-align: middle;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
}

.y-align-t {
  vertical-align: top;
  & > * {
    vertical-align: top;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: top;
  }
}

.y-align-b {
  vertical-align: bottom;
  & > * {
    vertical-align: bottom;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: bottom;
  }
}

.x-align-l {
  text-align: left;
}

.x-align-m {
  text-align: center;
}

.x-align-r {
  text-align: right;
}
