@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;
}

.head {
  width: calc(100% - 48px);
  padding: 0 0 0 15px;
  &__row {
    &__x2 {
      line-height: 1.2;
    }
  }
  &__code {
    display: inline-block;
    max-width: calc(100% - 35px);
    font-size: 20px;
    color: var(--color-mirage);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__copy {
    margin: 0 0 0 5px;
    padding: 4px;
    position: relative;
    &__tooltip {
      display: inline-block;
      font-size: 12px;
      background-color: var(--color-french-pass);
      padding: 3px 6px;
      pointer-events: none;
      transform: translate(-50%, -5px);
      position: absolute;
      left: 50%;
      bottom: 100%;
    }
  }
  &__status {
    margin: 0 10px 0 0;
  }
  &__date {
    display: inline-block;
    font-size: 12px;
    color: var(--color-manatee);
    text-transform: capitalize;
    margin-bottom: -1px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-athens-gray);
  margin: 15px 0;
}

.roadmap {
  &__headline {
    font-weight: var(--bold);
    font-size: 16px;
    color: var(--color-primary);
    margin: 0 0 5px;
  }
  &__list {
    margin: 0 0 5px;
    &__item {
      padding: 4px 0;
      &:first-of-type {
        b {
          &::before {
            display: none;
          }
        }
        small {
          color: var(--color-gray);
        }
      }
      &:last-of-type {
        b {
          &::after {
            display: none;
          }
        }
      }
      b,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      i,
      small {
        display: block;
        width: 100%;
        color: var(--color-french-gray);
      }
      b {
        width: 8px;
        height: 8px;
        background-color: var(--color-french-gray);
        border-radius: 50%;
        margin: 0 10px 0 0;
        position: relative;
        &:before,
        &:after {
          content: '';
          display: block;
          width: 2px;
          height: 30px;
          background-color: var(--color-french-gray);
          transform: translateX(-50%);
          position: absolute;
          left: 50%;
        }
        &:before {
          top: -100%;
        }
        &:after {
          top: 100%;
        }
      }
      i {
        font-weight: var(--semi-bold);
        font-style: normal;
        font-size: 14px;
        margin: 0 10px 0 0;
      }
      small {
        font-size: 12px;
      }
      &__scheduled {
        b {
          background-color: var(--color-medium-purple);
        }
        i {
          color: var(--color-medium-purple);
        }
      }
      &__in_route {
        b {
          background-color: var(--color-gold-tips);
        }
        i {
          color: var(--color-gold-tips);
        }
      }
      &__delivered {
        b {
          background-color: var(--color-la-palma);
        }
        i {
          color: var(--color-la-palma);
        }
      }
      &__canceled {
        b {
          background-color: var(--color-valencia);
        }
        i {
          color: var(--color-valencia);
        }
      }
    }
  }
  &__link {
    color: var(--color-primary);
    span,
    i {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      font-weight: var(--semi-bold);
      font-style: normal;
      font-size: 14px;
    }
  }
}

.info {
  &__inner {
    margin: 0 0 15px;
    &:last-child {
      margin: 0;
    }
    &__reschedule {
      b {
        color: var(--color-accent);
      }
      p {
        display: block;
        width: 100%;
        margin: 5px 0 0;
      }
      a {
        font-weight: var(--medium);
        font-size: 14px;
        color: var(--color-primary);

        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__pending {
      font-weight: var(--semi-bold);
      font-weight: 14px;
      color: var(--color-accent);
    }
  }
  h6 {
    font-weight: var(--semi-bold);
    font-size: 16px;
    color: var(--color-primary);
  }
  p {
    color: var(--color-scarpa-flow);
    margin: 0;
  }
  a {
    color: var(--color-primary);
  }
}
