@import 'src/@core/styles/scss/includes.scss';
.root {
  margin: 0 -7.5px 20px;
  &__item {
    padding: 0 7.5px;
    &__mobile {
      width: 50%;
      margin: 15px 0 0;
      &:nth-child(1),
      &:nth-child(2) {
        margin: 0;
      }
    }
    &__desktop {
      width: 25%;
    }
    &__inner {
      height: 100%;
      background-color: var(--color-white);
      box-shadow: var(--box-shadow);
      border-radius: var(--border-radius);
      padding: 15px 10px 10px;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin: 0 0 5px;
      &__created {
        background-color: set-color(--color-medium-purple, 0.2);
        [stroke] {
          stroke: var(--color-medium-purple);
        }
      }
      &__on_route {
        background-color: set-color(--color-gold-tips, 0.2);
        [stroke] {
          stroke: var(--color-gold-tips);
        }
      }
      &__delivered {
        background-color: set-color(--color-la-palma, 0.2);
        [stroke] {
          stroke: var(--color-la-palma);
        }
      }
      &__failed {
        background-color: set-color(--color-valencia, 0.2);
        [stroke] {
          stroke: var(--color-valencia);
        }
      }
    }
    &__score {
      &__created {
        color: var(--color-medium-purple);
      }
      &__on_route {
        color: var(--color-gold-tips);
      }
      &__delivered {
        color: var(--color-la-palma);
      }
      &__failed {
        color: var(--color-valencia);
      }
    }
  }
  h3,
  h4 {
    margin: 0;
  }
  h3 {
    font-weight: var(--semi-bold);
    font-size: 14px;
    color: var(--color-mirage);
    line-height: 1.2;
    &.center {
      text-align: center;
    }
  }
  h4 {
    font-size: 20px;
  }
  a {
    font-weight: var(--medium);
    font-size: 12px;
    color: var(--color-primary);
  }
}
