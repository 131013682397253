@import 'src/@core/styles/scss/includes.scss';
.root {
  padding: 0 15px;
  &__inner {
    width: 100%;
    padding: 30px 20px;
  }
  &__headline {
    width: 100%;
    text-align: left;
    margin: 0 0 15px;
    h4 {
      font-size: 24px;
      color: var(--color-mirage);
      span {
        color: var(--color-primary);
      }
    }
  }
}
