/**
 * set elements configuration
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  border-radius: 0;
  outline: none;
  border: none;
  background-color: transparent;
  &:hover,
  &:focus {
    outline: none;
  }
}

button {
  padding: 0;
}

textarea {
  resize: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  &:hover,
  &:focus {
    outline: none;
  }
}

ul {
  display: block;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
}

svg {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

img {
  max-width: 100%;
  max-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.clearfix {
  *zoom: 1;
  &:before {
    content: '';
    display: table;
    line-height: 0;
    clear: both;
  }
  &:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both;
  }
}

[style^='background-image'] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
