@import 'src/@core/styles/scss/includes.scss';
.root {
  display: block;
  width: 100%;

  &__fieldset {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 5px 0;
    margin: 0 0 20px;
    position: relative;
  }

  &__item {
    width: 100%;
    position: relative;
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-mystic);
      position: absolute;
      top: 100%;
      left: 0;
    }
    &__inner {
      padding: 15px 20px;
    }
    h4 {
      font-weight: var(--semi-bold);
      font-size: 14px;
      color: var(--color-primary);
      margin: 0 0 2px;
    }
  }
  &__field {
    width: 100%;
    position: relative;
    input,
    select {
      display: block;
      width: 100%;
      font-size: 16px;
      margin: 0;
      padding: 0;
      transition: color var(--def-transition-300ms);
      &[disabled] {
        color: var(--color-mirage);
        opacity: 1;
      }
    }
    select {
      margin-left: -3px;
    }
    &__error {
      display: block;
      width: 100%;
      font-size: 12px;
      color: var(--color-error);
      line-height: 1.2;
      margin: 5px 0 0;
    }
  }
  &__select {
    &__chevron {
      pointer-events: none;
      transform: translateY(-50%);
      transition: transform var(--def-transition-300ms);
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 0;
      &__rotated {
        transform: translateY(-50%) rotate(-180deg);
      }
      &__success {
        [stroke] {
          stroke: var(--color-primary);
        }
      }
      &__error {
        [stroke] {
          stroke: var(--color-error);
        }
      }
    }
    &__dropdown {
      display: block;
      width: 100%;
      background-color: var(--color-white);
      border: 2px solid var(--color-mystic);
      border-radius: 12px;
      pointer-events: none;
      opacity: 0;
      transform: translateY(8px) scale(0.94);
      transition: opacity var(--def-transition-300ms),
        transform var(--def-transition-300ms);
      overflow: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      &__collapsed {
        pointer-events: all;
        opacity: 1;
        transform: translateY(14px) scale(1);
      }
      li {
        display: block;
        width: 100%;
        color: var(--color-manatee);
        padding: 8px 16px;
        transition: color var(--def-transition-300ms),
          background-color var(--def-transition-300ms);
        cursor: pointer;
        &:hover {
          color: var(--color-mirage);
          background-color: var(--color-whisper);
        }
      }
      span {
        font-size: 14px;
      }
    }
  }
  &__date {
    display: block;
    & > div {
      width: 100%;
      & > div {
        position: relative;
      }
    }
    span,
    input {
      font-size: 14px;
      color: var(--color-mirage);
    }
    input {
      height: 100%;
      &:invalid {
        background: none;
      }
    }
    [class*='react-datetime-picker__clear-button__icon'],
    [class*='react-datetime-picker__calendar-button'] {
      transition: var(--def-transition-300ms);
    }
    [class*='react-datetime-picker__clear-button__icon'] {
      stroke: var(--color-manatee);
      &:hover {
        stroke: var(--color-sunset-orange) !important;
      }
    }
    [class*='react-datetime-picker__calendar-button'] {
      stroke: var(--color-manatee);
      &:hover {
        stroke: var(--color-primary) !important;
      }
    }
  }
  &__submit {
    margin: 0 0 30px;
  }
}

.popup {
  h6 {
    font-size: 24px;
    color: var(--color-primary);
    margin: 0 0 10px;
  }
  p {
    margin: 0 0 10px;
    &:last-of-type {
      margin: 0;
    }
  }
}
