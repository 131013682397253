@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  padding: 0 0 0 260px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  &__inner {
    width: 100%;
    background-color: var(--color-white);
    // box-shadow: var(--box-shadow);
    padding: 10px 30px 0;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-athens-gray);
}
