@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  padding: 0 15px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0);
  transition: opacity var(--def-transition-300ms),
    transform var(--def-transition-300ms);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  &__active {
    pointer-events: all;
    opacity: 1;
    transform: translateY(15px);
  }
  &__inner {
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 25px 25px 25px 15px;
    position: relative;
    &__symetric {
      padding: 25px 15px;
    }
    & > div {
      &:last-of-type {
        width: calc(100% - 24px);
        padding: 0 0 0 15px;
      }
    }
    p,
    button {
      display: inline;
      font-size: 14px;
    }
    button {
      font-weight: var(--medium);
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
  &__close {
    transform: translate(-5px, 5px);
    position: absolute;
    top: 0;
    right: 0;
  }
}
