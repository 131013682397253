@import 'src/@core/styles/scss/includes.scss';
.root {
  display: inline-block;
  &__inner {
    width: 100%;
    height: auto;
    &__error {
      [fill*='#'] {
        stroke: var(--color-error);
      }
      [stroke*='#'] {
        stroke: var(--color-error);
      }
    }
    &__primary {
      [fill*='#'] {
        stroke: var(--color-primary);
      }
      [stroke*='#'] {
        stroke: var(--color-primary);
      }
    }
    * {
      transition: var(--def-transition-300ms);
    }
  }
}
