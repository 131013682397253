@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;

  &__roadmap {
    width: 100%;

    &__list {
      min-height: 100px;
      padding: 10px 0;
      position: relative;
      &__item {
        display: flex;
        align-items: flex-start;
        margin: 30px 0;
        &:first-child {
          margin-top: unset;
        }
        &:last-child {
          margin-bottom: unset;
          .root__roadmap__list__item__bullet__inner {
            &:after {
              display: none;
            }
          }
        }
        &__bullet,
        &__meta {
          display: block;
        }
        &__bullet {
          display: flex;
          justify-content: center;
          width: 41px;
          &__inner {
            width: 12px;
            height: 12px;
            border: 2px solid var(--color-french-gray);
            border-radius: 50%;
            position: relative;
            &:before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: var(--color-french-gray);
              border: 10px solid var(--color-white);
              border-radius: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 3;
            }
            &:after {
              content: '';
              display: block;
              width: 2px;
              height: 88px;
              background-color: var(--color-french-gray);
              transform: translate(3px, 7px);
              position: absolute;
              top: 100%;
              left: 0;
            }
            &__lg {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
              border: unset;
              &:before {
                background-color: transparent;
              }
              &:after {
                // height: 30px;
                transform: translate(17px, 7px);
              }
            }
            &__xlg {
              &:before {
                background-color: transparent;
              }
              &:after {
                height: 100px;
              }
            }
            &__created {
              background-color: var(--color-medium-purple-approx);
            }
            &__on_route {
              background-color: var(--color-gold-tips-approx);
            }
            &__delivered {
              background-color: var(--color-la-palma-approx);
            }
            &__failed {
              background-color: var(--color-valencia-approx);
            }
          }
        }
        &__meta {
          width: 100%;
          margin-top: -5px;
          padding-left: 15px;
          &__lg {
            margin-top: 7px;
          }
          b,
          small {
            display: block;
            width: 100%;
          }
          b {
            font-weight: var(--semi-bold);
            font-size: 16px;
            color: var(--color-mirage);
            margin: 0;
          }
          p {
            font-size: 14px;
            color: var(--color-mirage);
          }
          small {
            font-size: 14px;
            color: var(--color-manatee);
          }
          &__created {
            b {
              color: var(--color-medium-purple);
            }
          }
          &__on_route {
            b {
              color: var(--color-gold-tips);
            }
          }
          &__delivered {
            b {
              color: var(--color-la-palma);
            }
          }
          &__failed {
            b {
              color: var(--color-valencia);
            }
          }
        }
        &__bills {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          margin: 5px 0 0;

          li {
            display: inline-block;
            width: 42px;
            height: 42px;
            margin: 0 2px;
            cursor: pointer;

            &:hover {
              svg {
                stroke: var(--color-primary);
              }
            }

            &:first-child {
              margin-left: unset;
            }

            &:last-child {
              margin-right: unset;
            }
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: var(--def-transition-300ms);
            will-change: transform;
          }
        }
      }
    }
  }
}

@keyframes pulse-medium-purple {
  0% {
    box-shadow: 0px 0px 0px 0px set-color(--color-medium-purple, 0.3);
  }
  50% {
    box-shadow: 0px 0px 0px 6px set-color(--color-medium-purple, 0.3);
  }
  100% {
    box-shadow: 0px 0px 0px 6px set-color(--color-medium-purple, 0);
  }
}

@keyframes pulse-gold-tips {
  0% {
    box-shadow: 0px 0px 0px 0px set-color(--color-gold-tips, 0.3);
  }
  50% {
    box-shadow: 0px 0px 0px 6px set-color(--color-gold-tips, 0.3);
  }
  100% {
    box-shadow: 0px 0px 0px 6px set-color(--color-gold-tips, 0);
  }
}

@keyframes pulse-la-palma {
  0% {
    box-shadow: 0px 0px 0px 0px set-color(--color-la-palma, 0.3);
  }
  50% {
    box-shadow: 0px 0px 0px 6px set-color(--color-la-palma, 0.3);
  }
  100% {
    box-shadow: 0px 0px 0px 6px set-color(--color-la-palma, 0);
  }
}

@keyframes pulse-valencia {
  0% {
    box-shadow: 0px 0px 0px 0px set-color(--color-valencia, 0.3);
  }
  50% {
    box-shadow: 0px 0px 0px 6px set-color(--color-valencia, 0.3);
  }
  100% {
    box-shadow: 0px 0px 0px 6px set-color(--color-valencia, 0);
  }
}
