@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  &__simple {
    box-shadow: unset;
    border-radius: unset;
  }
  &__item {
    display: block;
    width: 100%;
    position: relative;
    &__simple {
      border-radius: var(--border-radius);
      transition: background-color var(--def-transition-300ms);
      &:hover {
        background-color: var(--color-mystic);
        &:before {
          width: 0;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-mystic);
        transform: translateX(-50%);
        transition: width var(--def-transition-300ms);
        position: absolute;
        top: 100%;
        left: 50%;
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &__inner {
      display: block;
      width: 100%;
      padding: 15px 45px 15px 15px;
      cursor: pointer;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-mystic);
      position: absolute;
      top: 100%;
      left: 0;
    }
    &__name,
    &__address {
      display: block;
      width: 100%;
      color: var(--color-mirage);
    }
    &__name {
      font-weight: var(--bold);
      font-size: 16px;
      margin: 0 0 5px;
      &__simple {
        color: var(--color-primary);
      }
    }
    &__address {
      font-size: 14px;
    }
    &__action {
      transform: translate(-15px, -50%);
      position: absolute;
      top: 50%;
      right: 0;
    }
  }
}
