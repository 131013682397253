@use 'sass:math';

@function rem($size) {
  $remSize: math.div($size, $rootFontSize);
  @return #{$remSize}rem;
}

@function vw($width, $target) {
  $vw-context: ($width * 0.01) * 1px;
  @return math.div($target, $vw-context) * 1vw;
}

@function vh($height, $target) {
  $vh-context: ($height * 0.01) * 1px;
  @return math.div($target, $vh-context) * 1vh;
}

@function lh($fz, $n: 1.5) {
  $lh: $fz * $n;
  @return #{$lh}px;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function set-font($font, $weight) {
  $font: map-get($font, $weight);
  @return str-replace($font, "'", '');
}

@function set-ff($family) {
  @return map-get($fonts, $family);
}

@function set-fw($weight: '--regular') {
  @return map-get($font-weight, $weight);
}

@function set-color($color-name, $alpha: 1, $mix: false, $percentage: 5) {
  $color: map-get($colors, $color-name);

  @if $mix == false {
    @return rgba($color, $alpha);
  } @else {
    @if $mix == lighten {
      @return lighten($color, $percentage);
    }

    @if $mix == darken {
      @return darken($color, $percentage);
    }
  }
}

@function convert($value, $currentUnit, $convertUnit) {
  @if $currentUnit == px {
    @if $convertUnit == ems {
      @return math.div($value, 16) + 0em;
    } @else if $convertUnit == percent {
      @return percentage(math.div($value, 16));
    }
  } @else if $currentUnit == ems {
    @if $convertUnit == px {
      @return $value * 16 + (0px);
    } @else if $convertUnit == percent {
      @return percentage($value);
    }
  } @else if $currentUnit == percent {
    @if $convertUnit == px {
      @return $value * math.div(16, 100) + (0px);
    } @else if $convertUnit == ems {
      @return math.div($value, 100) + 0em;
    }
  } @else if $currentUnit == pts {
    @if $convertUnit == px {
      @return $value * 1.3333 + (0px);
    } @else if $convertUnit == ems {
      @return math.div($value, 12) + 0em;
    } @else if $convertUnit == percent {
      @return percentage(math.div($value, 12));
    }
  }
}

@mixin css-vars($vars, $name: pf) {
  :root {
    @each $name, $value in $vars {
      #{$name}: #{$value};
    }
  }
}
