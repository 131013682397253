/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-manatee) var(--color-whisper);
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--color-whisper);
}

*::-webkit-scrollbar-track:hover {
  background-color: var(--color-french-gray);
}

*::-webkit-scrollbar-track:active {
  background-color: var(--color-french-gray);
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-manatee);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-shark);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--color-shark);
}
