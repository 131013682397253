.react-calendar {
  background-color: var(--color-white);
  border-width: 2px !important;
  border-style: solid;
  border-color: var(--color-mystic);
  border-radius: var(--border-radius);
  transform: translateY(5px);
  &__navigation {
    &__label {
      pointer-events: none;
      &__labelText {
        font-weight: var(--semi-bold);
        color: var(--color-mirage) !important;
        text-transform: uppercase;
      }
    }
    &__arrow {
      font-weight: var(--bold);
      color: var(--color-primary);
      font-size: 16px;
      &:hover {
        background: none !important;
      }
      &:disabled {
        color: var(--color-manatee);
        background: none !important;
      }
    }
  }
  &__month-view {
    &__weekdays {
      &__weekday {
        abbr {
          font-size: 12px;
          text-decoration: none;
          border-bottom: none;
        }
      }
    }
    &__days {
      &__day {
        color: var(--color-shark);
        border-width: 2px !important;
        border-style: solid !important;
        border-color: set-color(--color-mystic, 0) !important;
        border-radius: 8px;
        padding: 3%;
        &:hover {
          background-color: set-color(--color-whisper, 0) !important;
          border-color: set-color(--color-mystic, 1) !important;
        }
        &--weekend {
          color: var(--color-sunset-orange);
        }
      }
    }
  }
  &__tile {
    &:disabled {
      color: var(--color-manatee);
      background-color: transparent;
      cursor: default;
      &:hover {
        border: none !important;
      }
    }
    &--now {
      background-color: var(--color-whisper);
      border-radius: 8px;
      padding: 0;
    }
    &--active {
      color: var(--color-white);
      background-color: var(--color-primary);
      &:hover {
        background-color: var(--color-primary) !important;
        border-color: var(--color-primary) !important;
      }
    }
  }
}

.react-datetime-picker__clock {
  display: none;
}

.datepicker__field__transformmed {
  &__inactive {
    pointer-events: none;
  }
  .react-datetime-picker {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: unset;
    }
    &__inputGroup {
      display: flex;
      align-items: center;
      span,
      input {
        font-size: 16px;
        color: var(--color-mirage);

        @include placeholder {
          color: var(--color-manatee);
        }
      }
    }
  }
}
