@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  height: 100%;
  padding: 0 15px 15px;
  &__inner {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}
