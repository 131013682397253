@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  margin: 0 0 30px;
  &:last-child {
    margin: 0;
  }
  &__head {
    width: 100%;
    margin: 0 0 10px;
    &__created {
      color: var(--color-medium-purple);
    }
    &__on_route {
      color: var(--color-gold-tips);
    }
    &__delivered {
      color: var(--color-la-palma);
    }
    &__failed {
      color: var(--color-valencia);
    }
    h4 {
      font-weight: var(--semi-bold);
      font-size: 18px;
      span {
        color: var(--color-primary);
      }
    }
    &__action {
      font-weight: var(--semi-bold);
      font-size: 14px;
      color: var(--color-primary);
      line-height: 1.2;

      &__disabled {
        color: var(--color-cadet-blue);
        pointer-events: none;
        cursor: default;
      }
    }
  }
  &__body {
    &__not_found {
      width: 100%;
      text-align: center;
      p {
        color: var(--color-gray);
      }
    }
  }
}
