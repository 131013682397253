@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: 5px 30px 5px 260px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1030;

  p {
    margin-right: 15px;
  }
}
