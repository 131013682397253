@import 'src/@core/styles/scss/includes.scss';
.root {
  pointer-events: none;
  opacity: 0;

  &__active {
    pointer-events: all;
    opacity: 1;
  }

  &__inner {
    width: 98px;
    height: 98px;
  }
}
