@import 'src/@core/styles/scss/includes.scss';
.root {
  text-align: center;
}

.headline {
  h6 {
    font-weight: var(--regular);
    font-size: 14px;
    color: var(--color-text);
    margin: 0;
  }
  & + a {
    margin-top: 5px;
  }
}

.link {
  display: inline-block;
  font-weight: var(--semi-bold);
  font-size: 14px;
  color: var(--color-primary);
  &:hover {
    text-decoration: underline;
  }
}
