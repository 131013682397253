@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  padding: 0 8px;
  &__width {
    &_25 {
      width: 25%;
    }
    &_33 {
      width: 33.33%;
    }
    &_50 {
      width: 50%;
    }
    &_75 {
      width: 75%;
    }
    &_100 {
      width: 100%;
    }
  }
  &__inner {
    position: relative;
  }
  &__error__rel {
    margin-bottom: -14px;
  }
}

.field_wrapper {
  width: 100%;
  position: relative;
  &__checkbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.field {
  &__text,
  &__email,
  &__password,
  &__number,
  &__textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    color: var(--color-mirage);
    background-color: var(--color-white);
    border: 2px solid var(--color-mystic);
    border-radius: var(--border-radius);
    padding: 16px;
    transition: color var(--def-transition-300ms),
      background-color var(--def-transition-300ms),
      border-color var(--def-transition-300ms);
    @include fieldAutofill {
      -webkit-text-fill-color: var(--color-primary) !important;
      -webkit-box-shadow: 0 0 0 30px var(--color-mystic) inset !important;
    }
    @include fieldAutofillText {
      font-family: var(--inter);
      font-weight: var(--regular);
      font-size: 14px;
      color: var(--color-mirage);
    }
    @mixin placeholder {
      font-size: 14px;
      color: var(--color-white);
    }
    &__disabled {
      color: var(--color-manatee);
      background-color: set-color(--color-mystic, 0.5);
    }
  }
  &__select {
    display: block;
    width: 100%;
    font-size: 14px;
    color: var(--color-mirage);
    background-color: var(--color-white);
    border: 2px solid var(--color-mystic);
    border-radius: 12px;
    padding: 16px;
    transition: color var(--def-transition-300ms),
      background-color var(--def-transition-300ms),
      border-color var(--def-transition-300ms);
    cursor: pointer;
    @include fieldAutofill {
      -webkit-text-fill-color: var(--color-white) !important;
      -webkit-box-shadow: 0 0 0 30px var(--color-mystic) inset !important;
    }
    @include fieldAutofillText {
      font-family: var(--inter);
      font-weight: var(--regular);
      font-size: 14px;
      color: var(--color-mirage);
    }
    @include placeholder {
      font-size: 14px;
      color: var(--color-manatee);
    }
    &__chevron {
      pointer-events: none;
      transform: translate(-16px, -50%);
      transition: transform var(--def-transition-300ms);
      position: absolute;
      top: 50%;
      right: 0;
      &__rotated {
        transform: translate(-16px, -50%) rotate(-180deg);
      }
      &__success {
        [stroke] {
          stroke: var(--color-primary);
        }
      }
      &__error {
        [stroke] {
          stroke: var(--color-error);
        }
      }
    }
    &__dropdown {
      display: block;
      width: 100%;
      background-color: var(--color-white);
      border: 2px solid var(--color-mystic);
      border-radius: 12px;
      pointer-events: none;
      opacity: 0;
      transform: translateY(8px) scale(0.94);
      transition: opacity var(--def-transition-300ms),
        transform var(--def-transition-300ms);
      overflow: hidden;
      position: absolute;
      // top: 100%;
      top: 53px;
      left: 0;
      z-index: 2;
      &__collapsed {
        pointer-events: all;
        opacity: 1;
        transform: translateY(4px) scale(1);
      }
      li {
        display: block;
        width: 100%;
        color: var(--color-manatee);
        padding: 8px 16px;
        transition: color var(--def-transition-300ms),
          background-color var(--def-transition-300ms);
        cursor: pointer;
        &:hover {
          color: var(--color-mirage);
          background-color: var(--color-whisper);
        }
      }
      span {
        font-size: 14px;
      }
    }
  }
  &__checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: var(--color-white);
    border: 2px solid var(--color-mystic);
    border-radius: 6px;
    transition: border-color var(--def-transition-300ms);
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: var(--color-primary);
      border-radius: 4px;
      overflow: hidden;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
      transition: opacity var(--def-transition-300ms),
        transform var(--def-transition-300ms);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &__hidden {
      pointer-events: none;
      position: absolute;
      left: -9999px;
      z-index: -1;
      &:checked {
        & + label {
          border-color: var(--color-primary);
          &:before {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
  }
  &__radio {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: var(--color-white);
    border: 2px solid var(--color-mystic);
    border-radius: 50%;
    vertical-align: middle;
    transition: border-color var(--def-transition-300ms);
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      background-color: var(--color-primary);
      border-radius: 50%;
      overflow: hidden;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
      transition: opacity var(--def-transition-300ms),
        transform var(--def-transition-300ms);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &__hidden {
      pointer-events: none;
      position: absolute;
      left: -9999px;
      z-index: -1;
      &:checked {
        & + label {
          border-color: var(--color-primary);
          &:before {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
  }
  &__datetime {
    display: block;
    width: 100%;
    & > div {
      width: 100%;
      background-color: var(--color-white);
      border: 2px solid var(--color-mystic);
      border-radius: 12px;
      padding: 11px 16px;
      transition: color var(--def-transition-300ms),
        background-color var(--def-transition-300ms),
        border-color var(--def-transition-300ms);
      & > div {
        position: relative;
      }
    }
    span,
    input {
      font-size: 14px;
      color: var(--color-mirage);
    }
    input {
      height: 100%;
      &:invalid {
        background: none;
      }
    }
    [class*='react-datetime-picker__clear-button__icon'],
    [class*='react-datetime-picker__calendar-button'] {
      transition: var(--def-transition-300ms);
    }
    [class*='react-datetime-picker__clear-button__icon'] {
      stroke: var(--color-manatee);
      &:hover {
        stroke: var(--color-sunset-orange) !important;
      }
    }
    [class*='react-datetime-picker__calendar-button'] {
      stroke: var(--color-manatee);
      &:hover {
        stroke: var(--color-primary) !important;
      }
    }
  }
  &__success {
    color: var(--color-primary);
    background-color: set-color(--color-primary, 0.1);
    border-color: var(--color-primary);
    @include placeholder {
      font-size: 14px;
      color: var(--color-manatee);
    }
  }
  &__error {
    color: var(--color-error);
    background-color: set-color(--color-error, 0.2);
    border-color: var(--color-error);
    @include placeholder {
      color: var(--color-error);
    }
  }
  &__toggle_password {
    display: inline-block;
    width: 24px;
    height: 24px;
    transform: translate(-16px, -50%);
    position: absolute;
    top: 50%;
    right: 0;
    span {
      @include abs-center;
    }
  }
  &__action_button {
    font-weight: var(--semi-bold);
    font-size: 14px;
    color: var(--color-primary);
    transform: translate(-16px, -50%);
    transition: opacity var(--def-transition-300ms);
    position: absolute;
    top: 50%;
    right: 0;
    // &__disabled {
    //   opacity: 0.5;
    // }
  }
  &__has_icon {
    padding: 16px 16px 16px 46px;
  }
  &__icon {
    width: 24px;
    height: 24px;
    transform: translate(15px, -50%);
    position: absolute;
    top: 50%;
    left: 0;
  }
  &__label {
    display: block;
    font-weight: var(--medium);
    font-size: 14px;
    margin: 0 0 5px;
  }
}

.label {
  &__checkbox {
    display: inline-block;
    max-width: calc(100% - 30px);
    font-size: 14px;
    color: var(--color-manatee);
    margin: 0 0 0 10px;
    cursor: pointer;
    user-select: none;
    position: relative;
    a {
      font-weight: var(--semi-bold);
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__radio {
    display: inline-block;
    font-size: 14px;
    color: var(--color-manatee);
    margin: 0 0 0 10px;
    vertical-align: middle;
    transition: color var(--def-transition-300ms);
    cursor: pointer;
    user-select: none;
    position: relative;

    &:hover {
      color: var(--color-mirage);
    }
  }
}

.error {
  &__text {
    display: block;
    width: 100%;
    font-size: 12px;
    color: var(--color-error);
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
  }
  &__text,
  &__email,
  &__password,
  &__number,
  &__textarea,
  &__select,
  &__datetime {
    span {
      transform: translate(18px, 2px);
    }
  }
  &__checkbox {
    span {
      transform: translateX(30px);
    }
  }
  &__relative {
    padding: 5px 5px 0;
    position: relative;
    top: auto;
    left: auto;

    span {
      transform: unset;
      position: relative;
      top: auto;
      left: auto;
    }
  }
}
