@import 'src/@core/styles/scss/includes.scss';
.root {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  &__alignment {
    &_start {
      justify-content: flex-start;
    }
    &_center {
      justify-content: center;
    }
    &_end {
      justify-content: flex-end;
    }
  }
  &__title {
    font-weight: var(--extra-bold);
    line-height: 1.1;
    &__size {
      &_x3 {
        font-size: 26px;
        margin: 0 0 5px;
      }
    }
    span {
      color: var(--color-primary);
    }
  }
  &__description {
    * {
      font-size: 16px;
      color: var(--color-gray);
    }
  }
}
