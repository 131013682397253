@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  &__item {
    width: 100%;
    position: relative;
    &:last-child {
      &:after {
        display: none;
      }
    }
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      cursor: pointer;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-mystic);
      position: absolute;
      top: 100%;
      left: 0;
    }
    &__text {
      width: calc(100% - 48px);
      font-size: 16px;
      color: var(--color-mirage);
      white-space: nowrap;
      text-overflow: ellipsis;
      pointer-events: none;
      overflow: hidden;
    }
  }
}
