@import 'src/@core/styles/scss/includes.scss';
.root {
  position: relative;
  z-index: 2;
  &__mobile {
    margin: 0 0 30px;
  }
}

.form_control_row {
  flex-wrap: nowrap;
  margin: 0;
  & > div {
    padding: 0;
  }
  &__field {
    padding: 12px 16px 12px 46px;
  }
}

.dropdown,
.not_found {
  display: block;
  width: 100%;
  max-height: 180px;
  background-color: var(--color-white);
  border: 2px solid var(--color-mystic);
  border-radius: var(--border-radius);
  pointer-events: none;
  opacity: 0;
  transform: scale(0.98);
  transition: opacity var(--def-transition-300ms),
    transform var(--def-transition-300ms);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  &__active {
    pointer-events: all;
    opacity: 1;
    transform: translateY(5px) scale(1);
  }
  &__item {
    & {
      display: block;
      width: 100%;
    }
    &__inner {
      width: 100%;
      padding: 8px 16px;
      transition: background-color var(--def-transition-300ms);
      &:hover {
        background-color: var(--color-whisper);
      }
    }
    span {
      font-weight: var(--semi-bold);
      font-size: 14px;
      color: var(--color-mirage);
    }
    b {
      font-size: 12px;
      &.scheduled {
        color: var(--color-medium-purple);
      }
      &.on_route {
        color: var(--color-gold-tips);
      }
      &.delivered {
        color: var(--color-la-palma);
      }
      &.cancelled {
        color: var(--color-valencia);
      }
    }
  }
}

.not_found {
  padding: 8px 16px;
  p {
    font-size: 14px;
  }
}
