@import 'src/@core/styles/scss/includes.scss';
.root {
  &__stylized {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 15px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  p {
    font-size: 14px;
    color: var(--color-mirage);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px;
  }

  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
    margin: 0 0 10px;
  }

  p {
    margin: 0 0 15px;
    & + ol,
    & + ul {
      margin-top: -5px;
    }
  }

  ol,
  ul {
    padding-left: 15px;
    margin-bottom: 20px;
  }

  ol,
  ul {
    li {
      display: list-item;
      padding: 5px 0;
      // &:before {
      //   content: '\2022';
      //   display: inline-block;
      //   font-weight: var(--bold);
      //   color: var(--color-primary);
      //   margin: 0 10px 0 0;
      // }

      p {
        display: inline;
      }
    }
  }

  ul {
    li {
      list-style-type: disc;
      &::marker {
        color: var(--color-primary);
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;
      &::marker {
        color: var(--color-primary);
      }
    }
  }

  a {
    font-weight: var(--medium);
    color: var(--color-primary);
    &:hover {
      text-decoration: underline;
    }
  }
}
