@import 'src/@core/styles/scss/includes.scss';
/**
 * imports
 */
// @import './includes.scss';
@import './common.scss';

/**
 * set css variables
 */
@include css-vars($fonts);
@include css-vars($font-weight);
@include css-vars($colors);
@include css-vars($transitions);
@include css-vars($custom);

/**
 * global styles
 */

html {
  &.disable-scroll {
    overflow: hidden;
  }
}

html,
body {
  background-color: var(--color-white);
  overflow-x: hidden;
  user-select: none;
}

body {
  font-family: var(--default-font-family);
  font-weight: var(--regular);
  font-size: 16px;
  color: var(--color-mirage);
  counter-reset: section;
  line-height: 1.4;
}

main,
header,
section,
footer {
  width: 100%;
}

/**
 * Site container
 */
.x-container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 1;
}

.x-container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

/**
 * Custom
 */
