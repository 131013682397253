@import 'src/@core/styles/scss/includes.scss';
.root {
  display: inline-block;
  width: 100%;
  text-align: center;
  border: 2px solid;
  border-radius: 12px;
  transition:
    color var(--def-transition-300ms),
    background-color var(--def-transition-300ms),
    border-color var(--def-transition-300ms),
    box-shadow var(--def-transition-300ms);
  &.size {
    &_md {
      font-weight: var(--semi-bold);
      font-size: 14px;
      padding: 14px 15px;
    }
    &_sm {
      width: auto;
      font-weight: var(--semi-bold);
      font-size: 12px;
      padding: 10px;
    }
  }
  &.fill {
    &.primary {
      color: var(--color-white);
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      &.shadow {
        box-shadow: 0 12px 20px set-color(--color-primary, 0.2);
      }
    }
    &.primary-white {
      color: var(--color-primary);
      background-color: var(--color-white);
      border-color: var(--color-white);
      &.shadow {
        box-shadow: 0 12px 20px set-color(--color-white, 0.2);
      }
    }
    &.sunset-orange {
      color: var(--color-white);
      background-color: var(--color-sunset-orange);
      border-color: var(--color-sunset-orange);
      &.shadow {
        box-shadow: 0 12px 20px set-color(--color-sunset-orange, 0.2);
      }
    }
  }
  &.stroke {
    &.primary {
      color: var(--color-primary);
      background-color: set-color(--color-primary, 0);
      border-color: var(--color-primary);
    }
    &.sunset-orange {
      color: var(--color-sunset-orange);
      background-color: set-color(--color-sunset-orange, 0);
      border-color: var(--color-sunset-orange);
    }
  }
  &.auto {
    width: auto;
  }
}
