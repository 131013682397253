.x-btn-group {
  width: 100%;
}

.x-btn {
  display: inline-block;
  font-weight: var(--medium);
  font-size: 14px;
  color: var(--white);
  line-height: 1.4;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  padding: 11px 24px;
  transition: color var(--def-transition-3ms),
    background-color var(--def-transition-3ms),
    border-color var(--def-transition-3ms), box-shadow var(--def-transition-3ms),
    opacity var(--def-transition-3ms), transform var(--def-transition-3ms);
  user-select: none;
  position: relative;
  @include hover {
    transform: translateY(-2px);
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }
  &__sm {
    padding: 8px 16px;
  }
  &__full {
    width: 100%;
  }
  &__loading {
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    margin: 0 5px 0 0;
    shape-rendering: auto;
    transform: translate(-10px, -50%) !important;
    position: absolute;
    top: 50%;
    right: 100%;
  }
}
