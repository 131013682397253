@import 'src/@core/styles/scss/includes.scss';
.root {
  display: block;
  width: 100%;
  padding: 15px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity var(--def-transition-300ms),
    transform var(--def-transition-300ms);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  &__active {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
  }
}

.mask {
  display: block;
  width: 100%;
  // pointer-events: none;
  // opacity: 0;
}

.field {
  width: 100%;
  margin: 0 0 15px;
  & > div {
    padding: 0;
  }
  &__inner {
    border-color: var(--color-primary);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  border-radius: var(--border-radius);
  margin: 0 0 15px;
  &__blink {
    animation: blinkBg 1.5s var(--linear) infinite;
    [stroke] {
      animation: blinkStroke 1.5s var(--linear) infinite;
    }
  }
}

@keyframes blinkBg {
  0% {
    background-color: var(--color-primary);
    border-color: var(--color-white);
  }
  50% {
    background-color: var(--color-white);
    border-color: var(--color-primary);
  }
  100% {
    background-color: var(--color-primary);
    border-color: var(--color-white);
  }
}

@keyframes blinkStroke {
  0% {
    stroke: var(--color-white);
  }
  50% {
    stroke: var(--color-primary);
  }
  100% {
    stroke: var(--color-white);
  }
}
