@import 'src/@core/styles/scss/includes.scss';
.headline {
  text-align: center;
  margin: 0 0 25px;
  h6 {
    font-weight: var(--regular);
    font-size: 14px;
    color: var(--color-text);
    margin: 0;
  }
}

.list {
  margin: 0 -8px;
  &__item {
    width: 100%;
    height: 58px;
    padding: 0 8px;
    &__max_width {
      width: 78px;
      margin: 0 10px;
      padding: 0;
    }
    &__inner {
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      border: 2px solid var(--color-mystic);
      border-radius: 12px;
      svg {
        max-height: 32px;
      }
    }
  }
}
