@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  margin: 0 0 20px;
  @include rsp(vpn) {
    margin: 0 0 15px;
  }
  h1 {
    font-weight: var(--extra-bold);
    font-size: 32px;
    color: var(--color-mirage);
    line-height: 1.2;
    @include rsp(vpn) {
      font-size: 26px;
    }
    strong,
    span {
      color: var(--color-primary);
    }
    & + p {
      margin: 5px 0 0;
    }
  }
  p {
    font-size: 14px;
    color: var(--color-gray);
  }
}
