@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin: 0 0 15px;
  &:last-child {
    margin: 0;
  }
  &__head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: var(--color-mirage);
    border-radius: var(--border-radius);
    padding: 15px 5px 15px 15px;
    &__text {
      display: block;
      max-width: calc(100% - 32px);
      width: 100%;
      font-weight: var(--medium);
      font-size: 16px;
      color: var(--color-mirage);
      text-align: left;
    }
    &__icon {
      width: 24px;
      height: 24px;
      position: relative;
      div {
        background-color: var(--color-cadet-blue);
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        &:first-of-type {
          width: 14px;
          height: 2px;
        }
        &:last-of-type {
          width: 2px;
          height: 14px;
        }
      }
    }
  }
  &__body {
    overflow: hidden;
    &__inner {
      margin-top: -10px;
      padding: 15px;
    }
  }
}
