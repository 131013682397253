@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin: 0 0 15px;
  padding: 15px;
  cursor: pointer;
  position: relative;
  &__icon {
    pointer-events: none;
    transform: translate(-7px, -50%);
    position: absolute;
    top: 50%;
    right: 0;
  }
  &__meta {
    width: calc(100% - 48px);
    padding: 0 40px 0 15px;
    h3 {
      font-weight: var(--semi-bold);
      font-size: 16px;
      color: var(--color-mirage);
    }
    div {
      line-height: 1.2;
    }
    &__status,
    &__date {
      display: inline-block;
      font-size: 12px;
    }
    &__status {
      margin: 0 10px 0 0;
    }
    &__date {
      color: var(--color-manatee);
    }
    &__edit {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 5px 0 0;
      span,
      i {
        pointer-events: none;
      }
      i {
        display: inline-block;
        font-weight: var(--medium);
        font-style: normal;
        font-size: 12px;
        color: var(--color-primary);
        margin: 0 0 0 5px;
      }
    }
  }
}
