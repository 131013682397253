#SavarX_PWA {
  .rdt {
    &_Table {
      background-color: transparent;
    }
    &_TableHead {
      border-radius: var(--border-radius);
      margin: 0 0 10px;
      overflow: hidden;
    }
    &_TableHeadRow {
      border: none;
    }
    &_TableCol {
      font-weight: var(--semi-bold);
      font-size: 14px;
      color: var(--color-mirage);
    }
    &_TableCell {
      font-size: 16px;
      color: var(--color-mirage);
    }
  }
}
