@import 'src/@core/styles/scss/includes.scss';
.root {
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1030;
  &__ios {
    padding: 10px 15px 30px;
  }
  &__android {
    padding: 10px 15px 15px;
  }
  &__desktop {
    padding: 10px 15px;
  }
}

.link {
  width: 68px;
  &__active {
    .link__text {
      color: var(--color-primary);
    }
  }
  &__text {
    display: inline-block;
    font-weight: var(--medium);
    font-size: 12px;
    color: var(--color-manatee);
    margin: 2px 0 0;
  }
}
